<template>
  <el-card>
    <div class="desiList">
      <div class="myheader_desi">
        <div class="vg_mtb_16">
          <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
          <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
          <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()"> 刷新</el-button>
          <el-date-picker
            class="vg_ml_8 vg_mr_8 topDateRangeSearch"
            size="small"
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="录入开始日期"
            end-placeholder="录入结束日期"
          />
          <el-button type="primary" size="small" icon="el-icon-search" @click="getDataList()"> 查询</el-button>
          <el-button type="warning" size="small" @click="copyRow" icon="el-icon-document-copy">复制并编辑</el-button>
          <el-button v-if="isManager" type="success" size="small" class="vg_ml_8" @click="jump('designStatisticalDraft')">设计图稿统计</el-button>
          <el-button v-if="isManager" type="success" size="small" class="vg_ml_8" @click="jump('statisticsOfCompletedTransactions')"> 成品成交统计</el-button>
          <el-button type="primary" size="small" class="vg_ml_8" @click="generateRequ"> 生成委托打样单</el-button>
          <el-button type="primary" size="small" class="vg_ml_8" @click="multipleItemNumberSelectionManuscript">多货号选择稿件</el-button>
        </div>
      </div>
      <search-table
        ref="multiTable"
        :data="dataList"
        :tableRowKey="`desi_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @select="selectRows"
        @selection-change="selectChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getDataList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </div>
    <el-dialog title="多货号选择附件" :visible.sync="dialogVisible" width="70%" @opened="getDialogData">
      <DynamicUTable
        ref="dialogTable"
        :table-data="dialogTableData"
        :needPagination="false"
        :need-check-box="false"
        :need-search="true"
        :columns="dialogTableProperties"
        :dbClickJp="dialogDbClick"
        @selection-change="val => (dialogSelection = val)"
        @getTableData="getDialogData"
      ></DynamicUTable>
    </el-dialog>
  </el-card>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import { get, getNoCatch, post } from '@/api/request';
import { desiAPI } from '@/api/modules/desi';
import { stffAPI } from '@/api/modules/staff';
import { optnAPI } from '@/api/modules/optn';
import helper from '@assets/js/helper.js';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/DevelopManagement/DesiManage/desi';
import { getDept } from '@api/public';
import { cloneDeep } from 'lodash';
import { arrayDeRepeat, getArrayIds } from '@assets/js/arrayUtils';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { getDateNoTime } from '@assets/js/dateUtils';
import { dequAPI } from '@api/modules/dequ';
import { docuAPI } from '@api/modules/docu';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'desiList',
  components: { DynamicUTable, SearchTable, pubPagination },
  data() {
    return {
      dateRange: [],
      tableProperties: cloneDeep(tableProperties),
      totalPage: 0,
      searchForm: {
        page_no: 1,
        desi_no: null, //设计单号
        prod_no: null, //我司货号
        desi_rank: null, //难易等级 //select
        prod_attr: null, //产品属性 select
        prod_type: null, //产品类别 select
        time: null, //要求交稿时间 ???
        desi_stff_id: null, // 设计人
        status: null, // 状态
        stff_id: null,
        timeValue1: null
      },
      dataList: [],
      btn: {},
      selectTab: [],
      loadFlag: false,
      users: [],
      levelList: [],
      typeList: [],
      attrList: [],
      stffUserList: [],
      imgUpdatatime: parseInt(Math.random() * 100000000),
      managerIds: [],
      dialogTableData: [],
      dialogTableProperties: [
        { label: '文件名', prop: 'docu_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '上传人', prop: 'dequ_designer_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
        {
          label: '上传时间',
          prop: 'create_time',
          itemType: 'input',
          input: false,
          sortable: true,
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        },
        {
          prop: 'docu_url',
          itemType: 'input',
          widthAuto: true,
          label: '图片',
          input: false,
          sortable: false,
          subItem: {
            type: 'image',
            thumbnail: row => helper.picUrl(row.docu_url) + '?x-oss-process=image/resize,m_lfit,h_200,w_200',
            image: row => helper.picUrl(row.docu_url)
          }
        }
      ],
      selectedProperties: [
        {
          prop: 'docu_url',
          itemType: 'input',
          widthAuto: true,
          label: '图片',
          input: false,
          sortable: false,
          subItem: {
            type: 'image',
            thumbnail: row => helper.picUrl(row.docu_url) + '?x-oss-process=image/resize,m_lfit,h_200,w_200',
            image: row => helper.picUrl(row.docu_url)
          }
        },
        { label: '文件名', prop: 'docu_name', itemType: 'input', input: true, sortable: false, widthAuto: true }
      ],
      dialogSelection: [],
      dialogVisible: false,
      form_ids: []
    };
  },
  computed: {
    isManager() {
      return this.managerIds.includes(this.$cookies.get('userInfo').stff_id);
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async initData() {
      this.managerIds = await getDept('', 'managerIds');
      this.getSelectList();
    },
    getDataList() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime?.getTime();
      searchForm.end_time = endTime?.getTime();
      getNoCatch(desiAPI.getDesis, searchForm).then(({ data }) => {
        this.btn = data.btn;
        this.totalPage = data.total;
        this.dataList = cloneDeep(data.list);
        setTimeout(() => {
          this.loadFlag = false;
        });
      });
    },
    copyRow() {
      if (this.selectTab.length !== 1) return this.$message.warning('只能选择一条');
      let { desi_id, status } = this.selectTab[0];
      if (status !== 2) return this.$message.warning('只能选择生效的数据!');
      getNoCatch(desiAPI.copy_desi_by_id, { desi_id: desi_id }).then(({ data }) => {
        this.$router.push({
          name: 'DesiAdd',
          query: { perm_id: this.$route.query.perm_id, t: Date.now() },
          params: { syncData: data.form }
        });
      });
    },
    buttonRefresh() {
      this.loadFlag = true;
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getDataList();
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getDataList();
    },
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump('desi_add?perm_id=' + permId);
    },
    doDelete() {
      if (this.selectTab.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      const desi_id_list = [];
      this.selectTab.forEach(item => {
        desi_id_list.push(item.desi_id);
      });
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          post(desiAPI.deleteDesiByDds, { desi_id_list })
            .then(res => {
              if (res.data.code === 0) {
                this.$message.success(res.data.msg);
                this.getDataList();
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    selectChange(selection) {
      this.selectTab = selection;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    getUsers() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.users = res.data.data;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    getSelectList() {
      // 难易等级
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10002 }).then(({ data }) => {
        this.tableProperties[11].options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });
      // 产品类别
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10004 }).then(({ data }) => {
        this.tableProperties[6].options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });
      // 产品属性
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10005 }).then(({ data }) => {
        this.tableProperties[7].options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });
    },
    getUser(status) {
      let str = '';
      this.users.forEach(item => {
        if (status === item.stff_id) {
          str = item.stff_name;
        }
      });
      return str;
    },
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/desi_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.desi_id
          })
        )
      });
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    async generateRequ() {
      if (this.selectTab.length === 0) return this.$message.warning('请选择一条数据!');
      if (this.selectTab.some(({ status }) => status !== 2)) return this.$message.warning('请选择生效的数据!');
      desiAPI.getRequListByDeptId(getArrayIds(this.selectTab, 'desi_id')).then(({ data }) => {
        console.log(123, data);
        this.$router.push({
          name: 'requAdd',
          query: { perm_id: 114 },
          params: { syncData: data }
        });
      });
    },
    multipleItemNumberSelectionManuscript() {
      if (this.selectTab.length === 0) return this.$message.warning('请选择数据!');
      this.dialogVisible = true;
    },
    getDialogData() {
      let searchForm = this.$refs.dialogTable.searchForm;
      searchForm.dequ_ids = arrayDeRepeat(getArrayIds(this.selectTab, 'dequ_id')).toString();
      searchForm.desi_ids = arrayDeRepeat(getArrayIds(this.selectTab, 'desi_id')).toString();
      dequAPI.getDequPterByDequIds(searchForm).then(({ data }) => {
        this.dialogTableData = data.list;
        this.form_ids = data.desi_ids;
      });
    },
    dialogDbClick(row) {
      docuAPI.addDocuPterList({ ...row, form_ids: this.form_ids, perm_id: 113 }).then(({ data }) => {
        this.dialogVisible = false;
        this.$message.success('操作成功!');
      });
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === '/desi_list') {
        this.getDataList();
      }
    }
  },
  filters: {
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row);
    }
  }
};
</script>

<style>
.mycontent_desi .img {
  width: 80px;
  height: 80px;
}
/* 改变EL-lable自带样式 */
.myheader_desi .el-form--inline .el-form-item__label {
  width: 90px;
  text-align: right;
}
</style>
